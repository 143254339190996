import { useQuery } from 'react-query';
import { getStatus } from '../index';

export const useGetStatus = () => {
  return useQuery({
    queryKey: ['getStatus'],
    queryFn: getStatus,
    refetchInterval: 20 * 1000,
  });
};
