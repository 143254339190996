import React from 'react';
import styled from 'styled-components';
import { PageTemplate } from '../../components/PageTemplate';
import { Heading } from '../../components/Heading';
import { useGetStatus } from '../../api/hooks';
import ServerStatusList from './ServerStatusList';

const SectionTitle = styled(Heading)`
  margin-top: 30px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
`;

export const Monitoring = () => {
  const { data, isLoading } = useGetStatus();

  return (
    <PageTemplate title="Monitoring">
      <section>
        <SectionTitle level={3} className="mb5">
          Server list status
        </SectionTitle>
        <ServerStatusList data={data} isLoading={isLoading} />
      </section>
    </PageTemplate>
  );
};
