import React from 'react';
import { ServerStatus } from '../../api';
import Spinner from '../../components/Spinner';
import ServerStatusItem from './ServerStatusItem';

type ServerStatusListProps = {
  data: ServerStatus[] | undefined;
  isLoading: boolean;
};

const ServerStatusList = ({ data, isLoading }: ServerStatusListProps) => {
  if (!data && !isLoading) return <div>No data!</div>;
  if (isLoading) return <Spinner />;

  return (
    <ul>
      {data?.map(s => (
        <ServerStatusItem status={s} key={`${s.name}_${s.ip}_${s.port}`} />
      ))}
    </ul>
  );
};

export default ServerStatusList;
