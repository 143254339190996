import React, { useMemo } from 'react';
import { Checkmark, Close } from 'grommet-icons';
import { ServerStatus } from '../../api';

type ServerStatusItemProps = {
  status: ServerStatus;
};

const ServerStatusItem = ({ status }: ServerStatusItemProps) => {
  const getIcon = useMemo(() => {
    if (status.status === 'active') {
      return <Checkmark size="small" color="green" />;
    }

    return <Close size="small" color="red" />;
  }, [status]);

  const getDataOrError = useMemo(() => {
    if (status.status === 'active') {
      return status.data.result;
    }

    // if (status.status === 'inactive') {
    //   return status.error;
    // }

    return null;
  }, [status]);

  return (
    <li>
      {status.name} [{status.ip}:{status.port}] {status.status} {getIcon}
      {getDataOrError}
    </li>
  );
};

export default ServerStatusItem;
